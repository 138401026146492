import store from "../../state/store";

/**
 * Processes a legacy event from the old action based window system
 * @param actionString {string} Legacy function argument string seperated by :
 * @param windowId {string} Window ID
 * @returns * @returns {Promise<void>}
 */
export default async function (actionString, windowId) {
  const [
    functionName,
    action,
    actionName,
    actionValue,
    actionValueType,
  ] = actionString.split(/:/g);

  global.session.windows[windowId][functionName](
    action,
    actionName,
    actionValue,
    actionValueType,
    windowId ??
      store.getters.subWindow.id ??
      store.getters["windows/activeWindowId"],
  );
}
